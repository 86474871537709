import { env } from '@/utils/env'

const Config = {
  isProduction: env.NODE_ENV === 'production' && env.REACT_APP_ENVIRONMENT === 'production',
  sentryDSN: env.REACT_APP_SENTRY_DSN,
  environment: env.REACT_APP_ENVIRONMENT,
  appVersion: env.REACT_APP_VERSION,
  baseUrl: env.VITE_API_URL,
  stagingHost: env.VITE_API_URL,
} as const

export default Config
