import queryString from 'query-string'

export const PAGE_NOT_FOUND = '/404'

export const AUTH = '/auth'
export const LOGIN = '/auth/login'
export const GXP_LOGIN = '/auth/gxp'

export const SERVICE = '/service'
export const PRESS_SERVICE = '/press-service'
export const AGENCY = '/agency'

// Dashbord routes
export const DASHBOARD = '/admin'
export const DASHBOARD_GXP = '/admin/gxp'

// public routes
export const HOME = '/'

// agency routes
export const AGENCY_HOME = 'agency'
export const AGENCY_UNIVERSITY = 'university'
export const AGENCY_UNIVERSITY_LINK = '/agency/university'
export const AGENCY_ABOUT_AGENCY = 'about-agency'
export const AGENCY_ABOUT_AGENCY_LINK = '/agency/about-agency'
export const AGENCY_MANAGEMENT = 'management'
export const AGENCY_MANAGEMENT_LINK = '/agency/management'
export const AGENCY_CENTRAL_APPARATUS = 'central-apparatus'
export const AGENCY_CENTRAL_APPARATUS_LINK = '/agency/central-apparatus'
export const AGENCY_RESEARCHINSTIT = 'research-institutes'
export const AGENCY_RESEARCHINSTIT_LINK = '/agency/research-institutes'
export const AGENCY_TASHKENTPHARM = 'tashkent-pharma-park'
export const AGENCY_TASHKENTPHARM_MEDIA = 'media/:mediaId'
export const AGENCY_TASHKENTPHARM_LINK = '/agency/tashkent-pharma-park'
export const AGENCY_ANTICORRUPTION = 'anticorruption'
export const AGENCY_ANTICORRUPTION_LINK = '/agency/anticorruption'
export const AGENCY_CONFLICTOFINTEREST = 'conflict-of-interest'
export const AGENCY_CONFLICTOFINTEREST_LINK = '/agency/conflict-of-interest'
export const AGENCY_PRACTICE_CENTER = 'practices-center'
export const AGENCY_PRACTICE_CENTER_LINK = '/agency/practices-center'
export const AGENCY_ABOUT_PORTAL = 'about-portal'
export const AGENCY_ABOUT_PORTAL_LINK = '/agency/about-portal'

// service routes
export const MANUFACTURERS = '/service/manufacturers'
export const MANUFACTURERS_DETAILS = '/service/manufacturers/:itemId'
export const DRUGS = '/service/drugs'
export const DRUGS_ITEM = '/service/drugs/:itemId'
export const DRUGS_DETAILS = '/service/drugs/:itemId/:detailsId'

// press-service routes
export const NEWS = '/press-service/news'
export const NEWS_DETAILS = '/press-service/news/:alias'
export const ABOUT_SERVICE = '/press-service/about-service'
export const PHOTO_GALLERY = '/press-service/photo-gallery'
export const VIDEO_GALLERY = '/press-service/video-gallery'
export const INFOGRAPHICS = '/press-service/infographics'

// Reference prices
export const REFERENCE_PRICES = '/reference-prices'

// Contacts
export const CONTACTS = '/contacts'

// private routes
export const PHARM_PRODUCT = '/pharm-product' // vaqtinchalik
export const ADMIN_PANEL = '/cabinet'
export const ADMIN_PANEL_GXP = '/cabinet/gxp'
export const ADMIN_PANEL_REGULATOR = '/cabinet/regulator'
export const ADMIN_PANEL_PHARM = '/cabinet/admin-panel'

// regulator routes
export const ADMIN_PANEL_REGULATOR_HOME = '/cabinet/regulator/home'
export const ADMIN_PANEL_REGULATOR_PROFILE = '/cabinet/regulator/profile'
export const ADMIN_PANEL_REGULATOR_STATISTICS = '/cabinet/regulator/statistics'
export const ADMIN_PANEL_REGULATOR_SETTINGS = '/cabinet/regulator/settings'
export const ADMIN_PANEL_REGULATOR_HANDBOOK = '/cabinet/regulator/handbook'
export const ADMIN_PANEL_REGULATOR_HANDBOOK_CREATE = '/cabinet/regulator/handbook/create'

// gxp routes
export const ADMIN_PANEL_GXP_HOME = '/cabinet/gxp/home'
export const ADMIN_PANEL_GXP_COMPANIES = '/cabinet/gxp/companies'
export const ADMIN_PANEL_GXP_COMPANIES_BRANCHES = '/cabinet/gxp/companies/branches'
export const ADMIN_PANEL_GXP_COMPANIES_BRANCH_ADD = '/cabinet/gxp/companies/branch/add'
export const ADMIN_PANEL_GXP_PRODUCTS = '/cabinet/gxp/products'
export const ADMIN_PANEL_GXP_REQUESTS = '/cabinet/gxp/requests'
export const ADMIN_PANEL_GXP_REQUESTS_CREATE = '/cabinet/gxp/requests/create'
export const ADMIN_PANEL_GXP_DOCUMENTS = '/cabinet/gxp/documents'
export const ADMIN_PANEL_GXP_SETTINGS = '/cabinet/gxp/settings'

// admin panel pharm portal
export const ADMIN_PANEL_PHARM_NEWS = '/cabinet/admin-panel/news'
export const ADMIN_PANEL_PHARM_ADD_NEWS = '/cabinet/admin-panel/news/add'
export const ADMIN_PANEL_PHARM_GALLERY = '/cabinet/admin-panel/gallery'
export const ADMIN_PANEL_PHARM_VIDEOGALLERY = '/cabinet/admin-panel/video-gallery'
export const ADMIN_PANEL_PHARM_INFOGRAPHICS = '/cabinet/admin-panel/infographics'
export const ADMIN_PANEL_PHARM_RUBRIC = '/cabinet/admin-panel/rubric'
export const ADMIN_PANEL_PHARM_REFERENCE_PRICES = '/cabinet/admin-panel/reference-prices'
export const ADMIN_PANEL_PHARM_ADD_REFERENCE_PRICE = '/cabinet/admin-panel/reference-price/add'

export const createWithQuery = (path: string, params: object) => {
  const query = queryString.stringify(params)
  return `${path}?${query}`
}

const paths = {
  PAGE_NOT_FOUND,
  AUTH,
  LOGIN,
  GXP_LOGIN,
  DASHBOARD,
  DASHBOARD_GXP,
  HOME,
  NEWS,
  NEWS_DETAILS,
  PHOTO_GALLERY,
  VIDEO_GALLERY,
  INFOGRAPHICS,
  ABOUT_SERVICE,
  MANUFACTURERS,
  DRUGS,
  REFERENCE_PRICES,
  CONTACTS,
  DRUGS_ITEM,
  DRUGS_DETAILS,
  PHARM_PRODUCT,
  ADMIN_PANEL,
  ADMIN_PANEL_PHARM,
  ADMIN_PANEL_GXP,
  ADMIN_PANEL_REGULATOR,
  MANUFACTURERS_DETAILS,
  agencyPaths: {
    AGENCY_HOME,
    AGENCY_ABOUT_PORTAL,
    AGENCY_UNIVERSITY,
    AGENCY_ABOUT_AGENCY,
    AGENCY_MANAGEMENT,
    AGENCY_CENTRAL_APPARATUS,
    AGENCY_RESEARCHINSTIT,
    AGENCY_TASHKENTPHARM,
    AGENCY_TASHKENTPHARM_MEDIA,
    AGENCY_ANTICORRUPTION,
    AGENCY_CONFLICTOFINTEREST,
    AGENCY_PRACTICE_CENTER,
  },
  gxpPaths: {
    ADMIN_PANEL_GXP_HOME,
    ADMIN_PANEL_GXP_COMPANIES,
    ADMIN_PANEL_GXP_COMPANIES_BRANCHES,
    ADMIN_PANEL_GXP_COMPANIES_BRANCH_ADD,
    ADMIN_PANEL_GXP_PRODUCTS,
    ADMIN_PANEL_GXP_REQUESTS,
    ADMIN_PANEL_GXP_REQUESTS_CREATE,
    ADMIN_PANEL_GXP_DOCUMENTS,
    ADMIN_PANEL_GXP_SETTINGS,
  },
  regulatorPaths: {
    ADMIN_PANEL_REGULATOR_HOME,
    ADMIN_PANEL_REGULATOR_PROFILE,
    ADMIN_PANEL_REGULATOR_STATISTICS,
    ADMIN_PANEL_REGULATOR_SETTINGS,
    ADMIN_PANEL_REGULATOR_HANDBOOK,
    ADMIN_PANEL_REGULATOR_HANDBOOK_CREATE,
  },
  adminPanelPharm: {
    ADMIN_PANEL_PHARM_NEWS,
    ADMIN_PANEL_PHARM_ADD_NEWS,
    ADMIN_PANEL_PHARM_GALLERY,
    ADMIN_PANEL_PHARM_VIDEOGALLERY,
    ADMIN_PANEL_PHARM_INFOGRAPHICS,
    ADMIN_PANEL_PHARM_RUBRIC,
    ADMIN_PANEL_PHARM_REFERENCE_PRICES,
    ADMIN_PANEL_PHARM_ADD_REFERENCE_PRICE,
  },
}

export default paths
