import FileServiceAPI from '@/api/requests/v1/fileService'
import { useMutation } from './useQuery'
import { AxiosProgressEvent } from 'axios'

const useFileService = () => {
  const getFiles = useMutation({
    mutationFn: (ids: string[]) => {
      return FileServiceAPI.getFiles(ids)
    },
  })

  const postFiles = useMutation({
    mutationFn: (body: FormData) => {
      return FileServiceAPI.postFiles(body)
    },
    onError: (error) => {
      console.log(error)
    },
    onSuccess: (data) => {
      console.log(data)
    },
  })

  const moveById = useMutation({
    mutationFn: (id: string) => {
      return FileServiceAPI.moveById(id)
    },
  })

  const postFilesMultiple = useMutation({
    mutationFn: (body: FormData) => {
      return FileServiceAPI.postFilesMultiple(body)
    },
  })

  const moveMultiple = useMutation({
    mutationFn: (ids: string[]) => {
      return FileServiceAPI.moveMultiple(ids)
    },
  })
  const uploadFileWithOutMutation = (
    body: FormData,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ) => {
    return FileServiceAPI.postFiles(body, onUploadProgress)
  }

  const uploadMultipleFilesWithOutMutation = (
    body: FormData,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ) => {
    return FileServiceAPI.postFilesMultiple(body, onUploadProgress)
  }

  const download = async (file_id: string) => {
    try {
      const response = await FileServiceAPI.download(file_id)
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file_id)
      document.body.appendChild(link)
      link.click()

      link.parentNode?.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Download failed:', error)
    }
  }

  return {
    getFiles,
    postFiles,
    moveById,
    postFilesMultiple,
    moveMultiple,
    uploadFileWithOutMutation,
    uploadMultipleFilesWithOutMutation,
    download,
  }
}

export default useFileService
