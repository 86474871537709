import { useSliderArrows } from '@/hooks/useSliderArrows'
import { useEffect, useState } from 'react'
import { CustomCarousel } from '../ui/carousel'
import styled from 'styled-components'
import { SliderArrows } from '../sliderArrows'
import { useNewsList } from '@/hooks/useNewsService'
import { formatDate, truncateStringByWords } from '@/utils/halper'
import { Link } from 'react-router-dom'
import { NEWS } from '@/routes/paths'

const Styles = styled.div`
  .custom-carousel {
    .slick-prev {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #dee3f0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-next {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #0d6efd;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

const News = () => {
  const { slider, handleBeforeChange } = useSliderArrows()
  const [slidesToShow, setSlidesToShow] = useState(3)

  const { data } = useNewsList()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1440) {
        setSlidesToShow(4)
      } else if (window.innerWidth >= 1024) {
        setSlidesToShow(3)
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2)
      } else {
        setSlidesToShow(1)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!data || data.length === 0) return null

  return (
    <Container>
      <Styles>
        <CustomCarousel
          beforeChange={handleBeforeChange}
          ref={slider}
          dots={false}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          className="custom-carousel flex justify-center px-4 lg:px-card"
        >
          {data?.map((item) => (
            <StyledLink to={NEWS + '/' + item.alias} key={item.id}>
              <div className="custom-carousel-item max-w-[360px] px-[33px] font-helvetica">
                <h3 className="mb-5 cursor-pointer text-xl font-bold leading-[120%] decoration-[#0D6EFD] decoration-2 underline-offset-4">
                  {truncateStringByWords(item.title, 90)}
                </h3>
                <p className="text-sm">{formatDate(item.createdAt)}</p>
                <p className="text-[#92BEFF]">{item.category}</p>
              </div>
            </StyledLink>
          ))}
        </CustomCarousel>
        <div className="absolute left-4 right-4 top-[30%] -z-10 lg:left-card lg:right-card">
          <SliderArrows items={data || []} sliderRef={slider} className="justify-between" />
        </div>
      </Styles>
    </Container>
  )
}

const Container = styled.section`
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  margin: 0;
  @media (min-width: 1024px) {
    margin: 0 -65px;
  }
  .slick-slide > div > div {
    display: flex !important;
    justify-content: center;
  }
`

const StyledLink = styled(Link)`
  z-index: 10000;
  cursor: pointer;
  padding-left: 40px;
  &:hover {
    text-decoration: none;
    color: initial;
    h3 {
      text-decoration: underline;
      text-decoration-color: #0d6efd;
    }
  }
`

export default News
