import { PaginationType } from '@/types'

export const isLoading = (state: boolean[]): boolean => {
  return state.some((item) => item === true)
}

export const hasNextPage = (data: PaginationType | undefined): boolean => {
  if (!data) return false
  const { totalPages, currentPage } = data
  return totalPages > currentPage
}

export const formatDate = (isoDate: string): string => {
  const date = new Date(isoDate)

  // Sana, oy va yilni olish
  const day = String(date.getDate()).padStart(2, '0') // 2 raqamli formatda
  const month = String(date.getMonth() + 1).padStart(2, '0') // Oylarda 0 asoslangan bo'ladi, shuning uchun +1
  const year = date.getFullYear()

  // Sana.oy.yil formatida qaytarish
  return `${day}.${month}.${year}`
}

export const truncateStringByWords = (str: string, maxLength = 120) => {
  if (str.length <= maxLength) {
    return str
  }

  const truncated = str.slice(0, maxLength)

  const lastSpaceIndex = truncated.lastIndexOf(' ')

  return truncated.slice(0, lastSpaceIndex) + '...'
}
