import XLS from '@/assets/icons/xls2.svg'
import useFileService from '@/hooks/useFileService'
import { useAppSelector } from '@/redux/store'
import { useTranslation } from 'react-i18next'

const ReportFileForm = () => {
  const { fileData } = useAppSelector((state) => state.file)
  const { t } = useTranslation()
  const { download } = useFileService()
  const handleDownloadFile = () => {
    download(fileData.id)
  }
  return fileData?.id ? (
    <div key={fileData?.id} className="flex cursor-pointer gap-4" onClick={handleDownloadFile}>
      <img src={XLS} alt="excel" />
      <div className="w-[250px]">
        <span className="typography-body-xl font-bold text-primary">{t('referencePricesPage.referentFile')}</span>
      </div>
    </div>
  ) : null
}

export default ReportFileForm
