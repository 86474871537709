export const CreateDrugFormFields = {
  drugFullFormCode: 'drugFullFormCode',
  internationalNonProprietaryNameCodes: 'internationalNonProprietaryNameCodes',
  manufacturerId: 'manufacturerId',
  tradeNameUz: 'tradeNameUz',
  tradeNameRu: 'tradeNameRu',
  tradeNameEn: 'tradeNameEn',
  quantityInPrimaryPackaging: 'quantityInPrimaryPackaging',
  quantityInSecondaryPackaging: 'quantityInSecondaryPackaging',
  primaryPackagingTechnicalCode: 'primaryPackagingTechnicalCode',
  secondaryPackagingTechnicalCode: 'secondaryPackagingTechnicalCode',
  isMultiComponent: 'isMultiComponent',
  gtin: 'gtin',
  gstin: 'gstin',
  isNarcoticPsychotropic: 'isNarcoticPsychotropic',
  dispensation: 'dispensation',
  anatomicalTherapeuticChemicalGroupCodes: 'anatomicalTherapeuticChemicalGroupCodes',
  routesOfDrugAdministrationCode: 'routesOfDrugAdministrationCode',
  dosage: 'dosage',
  unitMeasureDosage: 'unitMeasureDosage',
  pharmaTherapeuticGroup: 'pharmaTherapeuticGroup',
}

export const CreateDrugFormMutationFields = {
  drugFullFormCode: 'drugFullFormCode',
  internationalNonProprietaryNameCodes: 'internationalNonProprietaryNameCodes',
  manufacturerId: 'manufacturerId',
  anatomicalTherapeuticChemicalGroupCodes: 'anatomicalTherapeuticChemicalGroupCodes',
  routesOfDrugAdministrationCode: 'routesOfDrugAdministrationCode',
  unitMeasureDosage: 'unitMeasureDosage',
  primaryPackagingTechnicalCode: 'primaryPackagingTechnicalCode',
  secondaryPackagingTechnicalCode: 'secondaryPackagingTechnicalCode',
  pharmaTherapeuticGroup: 'pharmaTherapeuticGroup',
}

export const APP_NAME = 'UzPharma'

export const pathMapForDashboardMenu: {
  [key: string]: string
} = {
  home: 'home',
  companies: 'companies',
  products: 'products',
  requests: 'requests',
  documents: 'documents',
  settings: 'settings',
  'reference-prices': 'referencePrices',
}

export enum LanguageCode {
  EN = 'EN',
  RU = 'RU',
  UZ = 'UZ',
}

export enum NewsStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum VisibilityStatus {
  BANNER = 'BANNER',
  NEWS_LIST = 'NEWS_LIST',
}

export enum ImageType {
  TEMP = 'TEMP',
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}
