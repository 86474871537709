import { IconPlus } from '@/utils/icons/plus'
import { FilledButton } from '../ui/filledButton'

type ContentTitleProps = {
  title: string
  onClick?: () => void
  btnTitle?: string
  rightComponent?: React.ReactNode
  subTitle?: React.ReactNode
  hideTitleIcon?: boolean
}

const ContentTitle = ({ title, onClick, btnTitle, rightComponent, subTitle, hideTitleIcon }: ContentTitleProps) => {
  return (
    <div className="mb-5 flex items-center justify-between">
      <div>
        <div className="flex items-center gap-4">
          {!hideTitleIcon && (
            <div className="pb-1">
              <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.53906 16H12.5391V14H4.53906V16ZM4.53906 12H12.5391V10H4.53906V12ZM2.53906 20C1.98906 20 1.51823 19.8042 1.12656 19.4125C0.734896 19.0208 0.539062 18.55 0.539062 18V2C0.539062 1.45 0.734896 0.979167 1.12656 0.5875C1.51823 0.195833 1.98906 0 2.53906 0H10.5391L16.5391 6V18C16.5391 18.55 16.3432 19.0208 15.9516 19.4125C15.5599 19.8042 15.0891 20 14.5391 20H2.53906ZM9.53906 7V2H2.53906V18H14.5391V7H9.53906Z"
                  fill="#0D6EFD"
                />
              </svg>
            </div>
          )}
          <h2 className="typography-h3 font-bold">{title}</h2>
        </div>
        {subTitle ? subTitle : null}
      </div>
      {rightComponent
        ? rightComponent
        : btnTitle && (
            <FilledButton onClick={onClick} icon={<IconPlus />} iconPosition="start" className="gap-5 px-6 py-6">
              {btnTitle}
            </FilledButton>
          )}
    </div>
  )
}

export default ContentTitle
