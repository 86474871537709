import { FileDataType } from '@/types'
import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
  fileData: {
    id: '',
    name: '',
    path: '',
    type: '',
  } as FileDataType,
}

const slice = createSlice({
  name: 'file',
  initialState: defaultState,
  reducers: {
    setFileData: (state, action) => {
      state.fileData = action.payload
    },
    clearFileData: (state) => {
      state.fileData = defaultState.fileData
    },
  },
})

const { actions, reducer } = slice
export const { setFileData } = actions
export default reducer
