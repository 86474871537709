import { baseApiClient, BaseClient } from '@/api/baseClient'
import { FileUploadResponse, FileUrlsResponse, MultipleFileUploadResponse } from '@/types'
import { AxiosProgressEvent, AxiosResponse } from 'axios'

const urls = {
  getFiles: '/file-api/v1/files/info',
  postFiles: '/file-api/v1/files',
  moveById: (id: string) => `/file-api/v1/files/${id}/move`,
  postFilesMultiple: '/file-api/v1/files/multiple',
  moveMultiple: '/file-api/v1/files/move',
  download: (file_id: string) => `/file-api/v1/files/download/${file_id}`,
}

export class FileServiceAPI {
  constructor(private api: BaseClient) {}

  getFiles = async (ids: string[]) => {
    const result: AxiosResponse<FileUrlsResponse> = await this.api.get(urls.getFiles, { ids })

    return result
  }

  postFiles = async (body: FormData, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) => {
    const result: AxiosResponse<FileUploadResponse> = await this.api.post(urls.postFiles, body, {
      headers: {
        'Content-Type': 'form-data',
      },
      onUploadProgress(progressEvent) {
        onUploadProgress && onUploadProgress(progressEvent)
      },
    })

    return result
  }

  moveById = async (id: string) => {
    const result: AxiosResponse<FileUploadResponse> = await this.api.post(urls.moveById(id))

    return result
  }

  postFilesMultiple = async (body: FormData, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) => {
    const result: AxiosResponse<MultipleFileUploadResponse> = await this.api.post(urls.postFilesMultiple, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent) {
        onUploadProgress && onUploadProgress(progressEvent)
      },
    })

    return result
  }

  moveMultiple = async (ids: string[]) => {
    const result: AxiosResponse<FileUploadResponse> = await this.api.post(urls.moveMultiple, { ids })

    return result
  }

  download = async (file_id: string) => {
    const result: AxiosResponse<Blob> = await this.api.get(urls.download(file_id))

    return result
  }
}

export default new FileServiceAPI(baseApiClient)
