import { Empty, Select, Skeleton, Table, TablePaginationConfig, TableProps } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomPagination } from '@/components/ui/pagination'

type CustomTableProps<T> = {
  dataSource: T[]
  headerTitle?: string
  pageSizeOptions?: {
    value: number
    label: string
  }[]
  columns: TableProps['columns']
  position?: TablePaginationConfig['position']
  expandedRowComponent?: React.ReactNode
  classes?: string
  page: number
  pageSize: number
  setPageSize: (size: number) => void
  setPage: (page: number) => void
  setAlphabetically?: (value: boolean) => void
  totalElements: number
  currentItems?: number
  rowClassName?: 'success' | 'warning' | 'error'
  defaultExpandAllRows?: boolean
  rowExpandable?: (record: T) => boolean
  expandRowByClick?: boolean
  hideHeader?: boolean
  hidePagination?: boolean
  footerLeft?: React.ReactNode
  footerRight?: React.ReactNode
}

interface TableRecord {
  statusKey?: string
  key: string
  desc?: string
}

export const CustomTable = <T extends TableRecord>({
  dataSource,
  columns,
  position,
  expandedRowComponent,
  classes,
  page,
  pageSize,
  totalElements,
  setPageSize,
  setPage,
  setAlphabetically,
  headerTitle,
  defaultExpandAllRows,
  rowExpandable,
  expandRowByClick,
  hideHeader,
  hidePagination,
  footerLeft,
  footerRight,
  ...props
}: CustomTableProps<T> & TableProps) => {
  const { t } = useTranslation()
  const [expandedRows, setExpandedRows] = useState<string[]>([])

  const toggleExpand = (key: string) => {
    setExpandedRows((prev) => (prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]))
  }

  return (
    <div className="relative py-5">
      {!hideHeader && (
        <div className="mb-6 flex justify-between">
          {headerTitle && <h1 className="text-[32px] font-bold">{headerTitle}</h1>}
          <div className={`flex flex-1 justify-end gap-10 ${!headerTitle && 'justify-between'}`}>
            <div className="flex items-center gap-4">
              <Select
                className="custom-select"
                defaultValue={true}
                style={{ minWidth: 140 }}
                options={[
                  { value: true, label: t('alphabetically') },
                  { value: false, label: t('nonAlphabetically') },
                ]}
                onChange={setAlphabetically}
              />
              <span className="typography-body font-semibold">{t('resultsPerPage')}</span>
              <Select
                className="custom-select"
                defaultValue={10}
                value={pageSize}
                style={{ width: '60px' }}
                options={[
                  { value: 2, label: 2 },
                  { value: 5, label: 5 },
                  { value: 10, label: 10 },
                  { value: 12, label: 12 },
                  { value: 15, label: 15 },
                ]}
                onChange={(value: number) => {
                  setPageSize(value)
                }}
              />
            </div>
            <div className="hidden md:block">
              <CustomPagination
                current={page}
                pageSize={pageSize}
                total={totalElements}
                onChange={(page: number) => {
                  setPage(page)
                }}
              />
            </div>
          </div>
        </div>
      )}
      <Table
        bordered={false}
        className={`custom-table ${classes}`}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowClassName={(record: T) =>
          `cursor-pointer ${expandedRows?.includes(record.key) ? 'expanded-row' : ''} ${record.statusKey}`
        }
        expandable={{
          showExpandColumn: false,
          expandRowByClick: !defaultExpandAllRows || expandRowByClick,
          defaultExpandAllRows,
          expandedRowRender: () => expandedRowComponent,
          rowExpandable: rowExpandable || (() => false),
          onExpand: (_, record: T) => {
            if (toggleExpand) toggleExpand(record.key)
          },
        }}
        locale={{
          emptyText: props.loading ? (
            Array.from({ length: pageSize }).map((_, i) => <Skeleton key={i} active className="mb-3" />)
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ),
        }}
        {...props}
        loading={false}
      />
      {!hidePagination && (
        <div className="mt-6 flex justify-start px-4 md:justify-end md:px-0">
          <CustomPagination
            current={page}
            pageSize={pageSize}
            total={totalElements}
            onChange={(page: number) => {
              setPage(page)
            }}
            left={footerLeft}
            right={footerRight}
          />
        </div>
      )}
    </div>
  )
}
