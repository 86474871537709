import { ChangeNewsStatusBodyType, GetAllNewsParms, UpdateNewsRequest } from '@/types/newsTypes'
import { useInfiniteQuery, useMutation, useQuery } from './useQuery'
import newsAPI from '@/api/requests/v1/newsAPI'
import { CreateNewsType } from '@/schema/createNewsSchema'
import { useMemo } from 'react'

export const useCreateNews = () => {
  const createNews = useMutation({
    mutationFn: (data: CreateNewsType) => {
      return newsAPI.createNews(data)
    },
  })

  return createNews
}

export const useGetNewsById = () => {
  const getNewsById = useMutation({
    mutationFn: (id: string) => {
      return newsAPI.getNewsById(id)
    },
  })

  return getNewsById
}

export const useGetAllNews = (params: GetAllNewsParms) => {
  const searchNews = useInfiniteQuery({
    queryKey: ['searchNews', params],
    queryFn: ({ pageParam }) => {
      return newsAPI.getAll(pageParam)
    },
    initialPageParam: params,
    refetchOnWindowFocus: true,
    getNextPageParam: (lastPage) => {
      const {
        last,
        pageable: { pageNumber },
      } = lastPage.data.result
      if (!last) {
        return { ...params, page: pageNumber + 1 }
      }
      return undefined
    },
  })

  const resData = useMemo(
    () => searchNews.data?.pages.map((page) => page.data.result.content).flat(),
    [searchNews.data],
  )

  const pagination = useMemo(() => {
    return {
      total: searchNews.data?.pages[searchNews.data.pages.length - 1].data.result.totalElements,
      current: searchNews.data?.pages[searchNews.data.pages.length - 1].data.result.number,
      pageSize: searchNews.data?.pages[searchNews.data.pages.length - 1].data.result.size,
    }
  }, [searchNews.data])

  return { ...searchNews, resData, pagination }
}

export const useBannerNews = () => {
  const bannerNews = useQuery({
    queryKey: ['bannerNews'],
    queryFn: async () => {
      const data = await newsAPI.getAll({ size: 3, visibilityStatus: 'BANNER' })

      return data.data.result.content
    },
  })

  return bannerNews
}

export const useNewsList = () => {
  const newsList = useQuery({
    queryKey: ['newsList'],
    queryFn: async () => {
      const data = await newsAPI.getAll({ size: 6, visibilityStatus: 'NEWS_LIST' })

      return data.data.result.content
    },
  })

  return newsList
}

export const useGetByAlias = () => {
  const getByAlias = useMutation({
    mutationFn: async (alias: string) => {
      const data = await newsAPI.getByAlias(alias)

      return data.data.result
    },
  })

  return getByAlias
}

export const useNewsViewCount = () => {
  const newsViewCount = useMutation({
    mutationFn: (id: string) => {
      return newsAPI.updateNewsViewCount(id)
    },
  })

  return newsViewCount
}

export const useUpdateNews = () => {
  const updateNews = useMutation({
    mutationFn: (data: UpdateNewsRequest) => {
      return newsAPI.updateNews(data)
    },
  })

  return updateNews
}

export const useChangeNewsStatus = () => {
  const changeNewsStatus = useMutation({
    mutationFn: ({ id, status }: { id: string; status: ChangeNewsStatusBodyType }) => {
      return newsAPI.changeNewsStatus(id, status)
    },
  })

  return changeNewsStatus
}
