import { baseApiClient, BaseClient } from '@/api/baseClient'
import { CreateNewsType } from '@/schema/createNewsSchema'
import { ApiResponse, ApiResponseWithContent } from '@/types'
import {
  ChangeNewsStatusBodyType,
  GetAllNewsParms,
  NewsDetailType,
  NewsListResponse,
  NewsResponse,
  UpdateNewsRequest,
} from '@/types/newsTypes'
import { AxiosResponse } from 'axios'

const urls = {
  createNews: '/pharm-portal-api/v1/news',
  getNewsById: '/pharm-portal-api/v1/news/',
  getAll: '/pharm-portal-api/v1/news/all',
  getByAlias: '/pharm-portal-api/v1/news/alias',
  updateNews: '/pharm-portal-api/v1/news/update',
  changeNewsStatus: '/pharm-portal-api/v1/news/status',
  updateNewsViewCount: (id: string) => `/pharm-portal-api/v1/news/${id}/view-count`,
}

export class NewsAPI {
  constructor(private api: BaseClient) {}

  createNews = async (body: CreateNewsType) => {
    const result: AxiosResponse<ApiResponseWithContent<NewsResponse>> = await this.api.post(urls.createNews, body)

    return result
  }

  getNewsById = async (id: string) => {
    const result: AxiosResponse<ApiResponseWithContent<NewsDetailType>> = await this.api.get(urls.getNewsById + id)

    return result
  }

  getAll = async (query: GetAllNewsParms) => {
    const result: AxiosResponse<ApiResponseWithContent<NewsListResponse>> = await this.api.get(urls.getAll, query)

    return result
  }

  getByAlias = async (alias: string) => {
    const result: AxiosResponse<ApiResponseWithContent<NewsDetailType>> = await this.api.get(urls.getByAlias, {
      alias,
    })

    return result
  }

  updateNewsViewCount = async (id: string) => {
    const result: AxiosResponse<ApiResponse<null>> = await this.api.post(urls.updateNewsViewCount(id))

    return result
  }

  updateNews = async (body: UpdateNewsRequest) => {
    const result: AxiosResponse<ApiResponseWithContent<NewsResponse>> = await this.api.put(urls.updateNews, body)

    return result
  }

  changeNewsStatus = async (id: string, status: ChangeNewsStatusBodyType) => {
    const result: AxiosResponse<ApiResponseWithContent<NewsDetailType>> = await this.api.put(urls.changeNewsStatus, {
      id,
      status,
    })

    return result
  }
}

export default new NewsAPI(baseApiClient)
